import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import i1 from "../img/about/ceh.jpg";
import i2 from "../img/about/pilorama.jpg";
import i3 from "../img/about/color.jpg";
import i4 from "../img/about/montazh.jpg";
import i5 from "../img/about/proekt.jpg";
import i6 from "../img/about/metall.jpg";

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function About() {
  const theme = useTheme();
  return (
    <Box
      sx={{ width: "100%", height: "100%", bgcolor: theme.palette.primary.light, flexGrow: 0 }}
    >
    <Typography
        sx={{
          bgcolor: theme.palette.primary.main,
          textAlign: "center",
          fontWeight: "bold",
          color: theme.palette.secondary.light,
          p: 3,
          mb: -1.7
        }}
        variant="h4"
      >
        МЫ - ЗАВОД И ВСЁ ДЕЛАЕМ САМИ !
      </Typography>
    <ImageList
      sx={{
        width: "100%",
        height: "100%",
        // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
        transform: 'translateZ(0)',
      }}
      rowHeight={200}
      variant="quilted"
      gap={2}
    >
      {itemData.map((item) => {
        const cols = item.featured ? 2 : 1;
        const rows = item.featured ? 3 : 3;

        return (
          <ImageListItem key={item.img} cols={cols} rows={rows}>
            <img
              {...srcset(item.img, 250, 200, rows, cols)}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              sx={{
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
              }}
              title={item.title}
              position="top"
              actionPosition="left"
            />
          </ImageListItem>
        );
      })}
    </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: i1,
    title: 'Столярный цех',
    featured: true,
  },
  {
    img: i2,
    title: 'Пилорама',
  },
  {
    img: i3,
    title: 'Покрасочный цех',
  },
  {
    img: i4,
    title: 'Монтаж "под ключ"',
  },
  {
    img: i5,
    title: 'Проектирование',
  },
  {
    img: i6,
    title: 'Цех металлообработки',
    featured: true,
  }
];