import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import mail from "../mail";
import {
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  TextField,
  styled,
} from "@mui/material";
import { palette } from "@mui/system";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { FormControl, useFormControlContext } from "@mui/base/FormControl";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "#f4f6f8",
  color: "#5f3d02",
  boxShadow: 24,
  p: 4,
};

const MainModal = ({ active, setActive, children }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setActive(false);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }));

  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: "",
      phone: "",
    },
  });
  const onSubmit = (data) => {
    handleClose();
    toast.success("Заявка успешно отправлена", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    mail(data.firstName, data.phone);
  };

  return (
    <div>
      <Modal
        open={!active ? open : setOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3} sx={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Обратная связь
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Заполните все поля и мы свяжемся с вами, чтобы ответить на все ваши вопросы 
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{ width: "70%" }}
                      required
                      id="outlined-required"
                      label="Имя"
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="phone"
                  control={control}
                  rules={{ validate: matchIsValidTel }}
                  render={({ field, fieldState }) => (
                    <MuiTelInput
                      required
                      sx={{ my: 2, width: "70%" }}
                      {...field}
                      helperText={
                        fieldState.invalid ? "Некорректный номер телефона" : ""
                      }
                      error={fieldState.invalid}
                      focusOnSelectCountry
                      defaultCountry="RU"
                    />
                  )}
                />
                <ColorButton
                variant="contained"
                size="small"
                type="submit"
                sx={{ maxWidth: 250, height: 50, fontSize: 15 }}
              >
                Оставить заявку
              </ColorButton>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default MainModal;
