import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import m1 from "../img/stairs/cement1.jpg";
import m2 from "../img/stairs/cement2.jpg";
import m3 from "../img/stairs/cement3.jpg";
import MainModal from "./modal/MainModal";

export default function StairsCement() {
  const theme = useTheme();
  const [active, setActive] = React.useState(false);
  const handleOpen = () => setActive(true);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }));
  return (
    <Box
      sx={{ width: "100%", bgcolor: theme.palette.primary.light, flexGrow: 1 }}
    >
      <MainModal active={active} setActive={setActive} />
      <Typography
        sx={{
          bgcolor: theme.palette.primary.main,
          textAlign: "center",
          fontWeight: "bold",
          color: theme.palette.secondary.light,
          p: 4,
        }}
        variant="h4"
      >
        ОБЛИЦОВКА БЕТОННЫХ ЛЕСТНИЦ
      </Typography>
      <Container fixed>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          container
          sx={{ flexGrow: 1, p: 1 }}
          columns={{ xs: 4, sm: 12, md: 12 }}
        >
          <Grid item xs="auto">
            <Card
              sx={{ maxWidth: 360, bgcolor: theme.palette.secondary.light }}
            >
              <CardMedia sx={{ height: 400 }} image={m1} title="" />
              <CardContent sx={{height: 174}}>
                <Typography
                  color="secondary"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Облицовка лестницы на металлокаркасе
                </Typography>
                <Typography variant="body1" color="secondary">
                  Ступени: дуб, Подступенки: дуб, Ограждение металл +
                  оцинкованные тяжи, Разворот: через площадку
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  alignSelf: "stretch",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <ColorButton onClick={handleOpen} size="large">
                  Получить консультацию
                </ColorButton>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs="auto">
            <Card
              sx={{ maxWidth: 360, bgcolor: theme.palette.secondary.light }}
            >
              <CardMedia sx={{ height: 400 }} image={m2} title="" />
              <CardContent sx={{height: 174}}>
                <Typography
                  color="secondary"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Облицовка бетонной лестницы со стеклянным ограждением
                </Typography>
                <Typography variant="body1" color="secondary">
                  Ступени: дуб, Ограждение: закаленное стекло, Две поворотные
                  площадки
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  alignSelf: "stretch",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <ColorButton onClick={handleOpen} size="large">
                  Получить консультацию
                </ColorButton>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs="auto">
            <Card
              sx={{ maxWidth: 360, bgcolor: theme.palette.secondary.light }}
            >
              <CardMedia sx={{ height: 400 }} image={m3} title="" />
              <CardContent >
                <Typography
                  color="secondary"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Облицовка бетонной лестницы с деревянным ограждением
                </Typography>
                <Typography variant="body1" color="secondary">
                  Ступени: дуб, Подступенки: дуб, Ограждение: дуб, Разворот:
                  через площадку, Балюстрада: дуб
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  alignSelf: "stretch",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <ColorButton onClick={handleOpen} size="large">
                  Получить консультацию
                </ColorButton>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
