import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import i1 from "../img/colorstair/1.jpg";
import i2 from "../img/colorstair/2.jpg";
import i3 from "../img/colorstair/3.jpg";
import i4 from "../img/colorstair/4.jpg";
import i5 from "../img/colorstair/5.jpg";
import i6 from "../img/colorstair/6.jpg";
import i7 from "../img/colorstair/7.jpg";
import i8 from "../img/colorstair/8.jpg";
import i9 from "../img/colorstair/9.jpg";
import i10 from "../img/colorstair/10.jpg";
import i11 from "../img/colorstair/11.jpg";
import i12 from "../img/colorstair/12.jpg";

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function ColorStair() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: theme.palette.primary.light,
        flexGrow: 0,
      }}
    >
      <Typography
        sx={{
          bgcolor: theme.palette.primary.main,
          textAlign: "center",
          fontWeight: "bold",
          color: theme.palette.secondary.light,
          p: 3,
          mb: -1.8,
        }}
        variant="h4"
      >
        ВЫКРАСЫ СТУПЕНЕЙ
      </Typography>
      <Typography
        sx={{
          bgcolor: theme.palette.primary.light,
          textAlign: "left",
          fontWeight: "bold",
          color: theme.palette.secondary.main,
          p: 2,
        }}
        variant="h5"
      >
        В случае индивидуального подхода к цвету, работает профессиональный
        деревщик, который подберет нужный цвет тон в тон
      </Typography>
      <ImageList
        sx={{
          width: "100%",
          height: "100%",
          // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
          transform: "translateZ(0)",
        }}
        rowHeight={200}
        variant="quilted"
        gap={2}
      >
        {itemData.map((item) => {
          const cols = item.featured ? 2 : 1;
          const rows = item.featured ? 3 : 3;

          return (
            <ImageListItem key={item.img} cols={cols} rows={rows}>
              <img
                {...srcset(item.img, 250, 200, rows, cols)}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                }}
                title={item.title}
                position="top"
                actionPosition="left"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: i1,
    title: 'Жасмин',
  },
  {
    img: i2,
    title: 'Серебристый',
  },
  {
    img: i3,
    title: 'Пшеничный',
  },
  {
    img: i4,
    title: 'Тёмный песок',
  },
  {
    img: i5,
    title: 'Медовый',
  },
  {
    img: i6,
    title: 'Гавана',
  },
  {
    img: i7,
    title: 'Бискотти',
  },
  {
    img: i8,
    title: 'Медный',
  },
  {
    img: i9,
    title: 'Манго-танго',
  },
  {
    img: i10,
    title: 'Мандариновое танго',
  },
  {
    img: i11,
    title: 'Горький шоколад',
  },
  {
    img: i12,
    title: 'Чёрная ночь',
  },
];
