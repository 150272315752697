import "./App.css";
import { AppBar, Container, ThemeProvider, createTheme } from "@mui/material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Header from "./components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StairsMetall from "./components/StairsMetall";
import StairsVint from "./components/StairsVint";
import StairsWood from "./components/StairsWood";
import StairsConsole from "./components/StairsConsole";
import StairsCement from "./components/StairsCement";
import Recense from "./components/Recense";
import CallbackBlock from "./components/CallbackBlock";
import About from "./components/About";
import Works from "./components/Works";
import ColorStair from "./components/ColorStair";
import CallbackBlock2 from "./components/CallbackBlock2";
import Contacts from "./components/Contacts";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#8e8567",
        light: "#bdd1cc"
      },
      secondary: {
        main: "#5f3d02",
        light: "#f4f6f8"
      },
      text: {
        main: "#f4f6f8"
      },
    },
  });
  return (
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Header />
        <StairsMetall />
        <StairsVint />
        <StairsWood />
        <StairsConsole />
        <StairsCement/>
        <Recense/>
        <CallbackBlock />
        <About />
        <Works />
        <ColorStair />
        <CallbackBlock2/>
        <Contacts />
      </ThemeProvider>
  );
}

export default App;
