import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import i1 from "../img/works/1.jpg";
import i2 from "../img/works/2.jpg";
import i3 from "../img/works/3.jpg";
import i4 from "../img/works/4.jpg";
import i5 from "../img/works/5.jpg";
import i6 from "../img/works/6.jpg";
import i7 from "../img/works/7.jpg";
import i8 from "../img/works/8.jpg";
import i9 from "../img/works/9.jpg";
import i10 from "../img/works/10.jpg";
import i11 from "../img/works/11.jpg";
import i12 from "../img/works/12.jpg";
import i13 from "../img/works/13.jpg";
import i14 from "../img/works/14.jpg";
import i15 from "../img/works/15.jpg";
import i16 from "../img/works/16.jpg";
import i17 from "../img/works/17.jpg";
import i18 from "../img/works/18.jpg";
import i19 from "../img/works/19.jpg";
import i20 from "../img/works/20.jpg";
import i21 from "../img/works/21.jpg";
import i22 from "../img/works/22.jpg";
import i23 from "../img/works/23.jpg";
import i24 from "../img/works/24.jpg";
import i25 from "../img/works/25.jpg";
import i26 from "../img/works/26.jpg";
import i27 from "../img/works/27.jpg";
import i28 from "../img/works/28.jpg";



export default function Works() {
  const theme = useTheme();
  return (
    <Box
      sx={{ width: "100%", height: "100%", bgcolor: theme.palette.primary.light, flexGrow: 0, my: -2 }}
    >
    <Typography
        sx={{
          bgcolor: theme.palette.primary.main,
          textAlign: "center",
          fontWeight: "bold",
          color: theme.palette.secondary.light,
          p: 3,
        }}
        variant="h4"
      >
        НАШИ РАБОТЫ
      </Typography>
      <Carousel autoPlay interval='5000'>
                <div>
                    <img src={i1} />
                </div>
                <div>
                    <img src={i2} />
                </div>
                <div>
                    <img src={i3} />
                </div>
                <div>
                    <img src={i4} />
                </div>
                <div>
                    <img src={i5} />
                </div>
                <div>
                    <img src={i6} />
                </div>
                <div>
                    <img src={i7} />
                </div>
                <div>
                    <img src={i8} />
                </div>
                <div>
                    <img src={i9} />
                </div>
                <div>
                    <img src={i10} />
                </div>
                <div>
                    <img src={i11} />
                </div>
                <div>
                    <img src={i12} />
                </div>
                <div>
                    <img src={i13} />
                </div>
                <div>
                    <img src={i14} />
                </div>
                <div>
                    <img src={i15} />
                </div>
                <div>
                    <img src={i16} />
                </div>
                <div>
                    <img src={i17} />
                </div>
                <div>
                    <img src={i18} />
                </div>
                <div>
                    <img src={i19} />
                </div>
                <div>
                    <img src={i20} />
                </div>
                <div>
                    <img src={i21} />
                </div>
                <div>
                    <img src={i22} />
                </div>
                <div>
                    <img src={i23} />
                </div>
                <div>
                    <img src={i24} />
                </div>
                <div>
                    <img src={i25} />
                </div>
                <div>
                    <img src={i26} />
                </div>
                <div>
                    <img src={i27} />
                </div>
                <div>
                    <img src={i28} />
                </div>
            </Carousel>
    </Box>
  );
}

const itemData = [
  {
    img: i1,
    title: 'Столярный цех',
    featured: true,
  },
  {
    img: i2,
    title: 'Пилорама',
  },
  {
    img: i3,
    title: 'Покрасочный цех',
  },
  {
    img: i4,
    title: 'Монтаж "под ключ"',
  },
  {
    img: i5,
    title: 'Проектирование',
  },
  {
    img: i6,
    title: 'Цех металлообработки',
    featured: true,
  }
];