import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import m1 from "../img/stairs/wood1.jpg";
import m2 from "../img/stairs/wood2.jpg";
import m3 from "../img/stairs/wood3.jpg";
import MainModal from "./modal/MainModal";

export default function StairsWood() {
  const theme = useTheme();
  const [active, setActive] = React.useState(false);
  const handleOpen = () => setActive(true);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }));
  return (
    <Box
      sx={{ width: "100%", bgcolor: theme.palette.primary.light, flexGrow: 1 }}
    >
      <MainModal active={active} setActive={setActive} />
      <Typography
        sx={{
          bgcolor: theme.palette.primary.main,
          textAlign: "center",
          fontWeight: "bold",
          color: theme.palette.secondary.light,
          p: 4,
        }}
        variant="h4"
      >
        ДЕРЕВЯННЫЕ ЛЕСТНИЦЫ
      </Typography>
      <Container fixed>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          container
          sx={{ flexGrow: 1, p: 1 }}
          columns={{ xs: 4, sm: 12, md: 12 }}
        >
          <Grid item xs="auto">
            <Card
              sx={{ maxWidth: 360, bgcolor: theme.palette.secondary.light }}
            >
              <CardMedia sx={{ height: 400 }} image={m1} title="" />
              <CardContent>
                <Typography
                  color="secondary"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Деревянная лестница с деревянным ограждением
                </Typography>
                <Typography variant="body1" color="secondary">
                  Деревянная П-образная лестница с площадкой
                </Typography>
                <Typography variant="body1" color="secondary">
                  Традиционная лестница закрытого типа.
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  alignSelf: "stretch",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <ColorButton onClick={handleOpen} size="large">
                  Получить консультацию
                </ColorButton>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs="auto">
            <Card
              sx={{ maxWidth: 360, bgcolor: theme.palette.secondary.light }}
            >
              <CardMedia sx={{ height: 400 }} image={m2} title="" />
              <CardContent>
                <Typography
                  color="secondary"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Деревянная лестница из дуба с кованым ограждением
                </Typography>
                <Typography variant="body1" color="secondary">
                  Закрытая деревянная П-образная лестница с площадкой
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  alignSelf: "stretch",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <ColorButton onClick={handleOpen} size="large">
                  Получить консультацию
                </ColorButton>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs="auto">
            <Card
              sx={{ maxWidth: 360, bgcolor: theme.palette.secondary.light }}
            >
              <CardMedia sx={{ height: 400 }} image={m3} title="" />
              <CardContent>
                <Typography
                  color="secondary"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Лестница из дуба со стеклянным ограждением
                </Typography>
                <Typography variant="body1" color="secondary">
                  Лестница на моно косоуре с поворотной площадкой и стеклянным
                  ограждением на точечном креплении
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  alignSelf: "stretch",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <ColorButton onClick={handleOpen} size="large">
                  Получить консультацию
                </ColorButton>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
