import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import i1 from "../img/recense/1.png";
import i2 from "../img/recense/2.png";
import i3 from "../img/recense/3.png";
import i4 from "../img/recense/4.png";
import i5 from "../img/recense/5.png";
import i6 from "../img/recense/6.png";
import i7 from "../img/recense/7.png";
import i8 from "../img/recense/8.png";
import i9 from "../img/recense/9.png";
import i10 from "../img/recense/10.png";
import i11 from "../img/recense/11.png";
import i12 from "../img/recense/12.png";
import MainModal from "./modal/MainModal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function Recense() {
  const theme = useTheme();
  const [active, setActive] = React.useState(false);
  const handleOpen = () => setActive(true);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }));
  return (
    <Box
      sx={{ width: "100%", height: "100%", bgcolor: theme.palette.primary.light, flexGrow: 1, mb: -3 }}
    >
      <MainModal active={active} setActive={setActive} />
      <Typography
        sx={{
          bgcolor: theme.palette.primary.main,
          textAlign: "center",
          fontWeight: "bold",
          color: theme.palette.secondary.light,
          p: 3,
        }}
        variant="h4"
      >
        ОТЗЫВЫ
      </Typography>
      <Container fluid>
      <Carousel autoPlay centerMode interval='5000' dynamicHeight>
                <div>
                    <img src={i1} />
                </div>
                <div>
                    <img src={i2} />
                </div>
                <div>
                    <img src={i3} />
                </div>
                <div>
                    <img src={i4} />
                </div>
                <div>
                    <img src={i5} />
                </div>
                <div>
                    <img src={i6} />
                </div>
                <div>
                    <img src={i7} />
                </div>
                <div>
                    <img src={i8} />
                </div>
                <div>
                    <img src={i9} />
                </div>
                <div>
                    <img src={i10} />
                </div>
                <div>
                    <img src={i11} />
                </div>
                <div>
                    <img src={i12} />
                </div>
            </Carousel>
      </Container>
    </Box>
  );
}
