import {
  Box,
  Button,
  Container,
  Grid,
  Input,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React from "react";
import bg from "../img/bgrec2.jpg";
import { palette } from "@mui/system";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { FormControl, useFormControlContext } from "@mui/base/FormControl";
import mail from "./mail";

export default function CallbackBlock() {
  const theme = useTheme();
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }));

  const MuiTelInputStyled = styled(MuiTelInput)`
  & input {
    color: white;
  }
`

  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: "",
      phone: "",
    },
  });
  console.log(theme.palette.primary.main);
  const onSubmit = (data) => {
    toast.success("Заявка успешно отправлена", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    mail(data.firstName, data.phone);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundImage: `url(${bg})`,
        height: 700,
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "text.main",
        my: -1.8
      }}
    >
      <Container fixed>
        <Box sx={{ display: { xs: "flex", md: "flex", lg: "flex" } }}>
          <Stack sx={{ my: { xs: 10, md: 10, lg: 15 } }} spacing={5}>
            <Typography
              variant="h2"
              component="div"
              sx={{ maxWidth: "100%", fontWeight: "bold", fontSize: { xs: 40, md: 50, lg: 70 } }}
            >
              Необходима консультация ?
            </Typography>
            <Typography
              variant="inherit"
              component="div"
              sx={{ maxWidth: "100%", fontSize: { xs: 15, md: 20, lg: 25 } }}
            >
              Оставьте заявку на обратный звонок и наш менеджер свяжется с Вами для проведения консультации !
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Имя"
                    sx={{ width: "50%", color: "text.main" }}
                    required
                    id="outlined-required"
                    label="Имя"
                    {...field}
                  />
                )}
              />
              <br/>
              <Controller
                name="phone"
                control={control}
                rules={{ validate: matchIsValidTel }}
                render={({ field, fieldState }) => (
                  <MuiTelInputStyled
                    required
                    variant="standard"
                    sx={{ my: 2, width: "50%", color: "text.main" }}
                    {...field}
                    helperText={
                      fieldState.invalid ? "Некорректный номер телефона" : ""
                    }
                    error={fieldState.invalid}
                    focusOnSelectCountry
                    defaultCountry="RU"
                  />
                )}
              />
              <br/>
              <ColorButton
                variant="contained"
                size="small"
                type="submit"
                sx={{ maxWidth: 300, height: 80, fontSize: 24 }}
              >
                Оставить заявку
              </ColorButton>
            </form>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
