import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import m1 from "../img/stairs/metall1.jpg";
import m2 from "../img/stairs/metall2.jpg";
import m3 from "../img/stairs/metall3.jpeg";
import MainModal from "./modal/MainModal";

export default function StairsMetall() {
  const theme = useTheme();
  const [active, setActive] = React.useState(false);
  const handleOpen = () => setActive(true);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }));
  return (
    <Box
      sx={{ width: "100%", bgcolor: theme.palette.primary.light, flexGrow: 1 }}
    >
        <MainModal active={active} setActive={setActive}/>
      <Typography
        sx={{
          bgcolor: theme.palette.primary.main,
          textAlign: "center",
          fontWeight: "bold",
          color: theme.palette.secondary.light,
          p: 4,
        }}
        variant="h4"
      >
        ЛЕСТНИЦЫ НА МЕТАЛЛОКАРКАСЕ
      </Typography>
      <Container fixed>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          container
          sx={{ flexGrow: 1, p: 1 }}
          columns={{ xs: 4, sm: 12, md: 12 }}
        >
          <Grid item xs="auto">
            <Card
              sx={{ maxWidth: 360, bgcolor: theme.palette.secondary.light }}
            >
              <CardMedia sx={{ height: 400 }} image={m1} title="" />
              <CardContent>
                <Typography
                  color="secondary"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Комбинированная межэтажная лестница №1
                </Typography>
                <Typography variant="body1" color="secondary">
                  Лестница открытого типа на модульном металлическом каркасе с
                  дубовыми ступенями и ригельным ограждением.{" "}
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  alignSelf: "stretch",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <ColorButton onClick={handleOpen} size="large">Получить консультацию</ColorButton>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs="auto">
            <Card
              sx={{ maxWidth: 360, bgcolor: theme.palette.secondary.light }}
            >
              <CardMedia sx={{ height: 400 }} image={m2} title="" />
              <CardContent>
                <Typography
                  color="secondary"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Комбинированная межэтажная лестница №2
                </Typography>
                <Typography variant="body1" color="secondary">
                  Лестница открытого типа на стерео косоуре. легкая и невесомая,
                  не утяжеляет окружающее пространство.
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  alignSelf: "stretch",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <ColorButton onClick={handleOpen} size="large">Получить консультацию</ColorButton>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs="auto">
            <Card
              sx={{ maxWidth: 360, bgcolor: theme.palette.secondary.light }}
            >
              <CardMedia sx={{ height: 400 }} image={m3} title="" />
              <CardContent>
                <Typography
                  color="secondary"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Комбинированная межэтажная лестница №3
                </Typography>
                <Typography variant="body1" color="secondary">
                  Лестница на моно косоуре с поворотной площадкой и стеклянным
                  ограждением на точечном креплении
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  alignSelf: "stretch",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <ColorButton onClick={handleOpen} size="large">Получить консультацию</ColorButton>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
